.projects-grid {
    a {
        text-decoration: none;
    }

    .load-more {
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .btn {
            outline: 0;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            background: #bc82ff;
            min-width: 250px;
            border: 0;
            border-radius: 50px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
            box-sizing: border-box;
            padding: 18px 25px;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            overflow: hidden;
            cursor: pointer;
        }

        .btn:hover {
            opacity: .95;
        }

        .btn .animation {
            border-radius: 100%;
            animation: ripple 0.6s linear infinite;
        }

        @keyframes ripple {
            0% {
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
            }

            100% {
                box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
            }
        }
    }

    .item-body {
        height: 450px;
        background-color: #1c152c;
        transition: all 0.3s ease-in-out;
        overflow: hidden;

        @media (max-width: 800px) {
            height: max-content;
        }

        &:hover {
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);

            .image-container {
                overflow: hidden;

                img {
                    scale: 1.1;
                    transition: all 0.3s ease-in-out;
                }
            }
        }

        .top {
            height: 75%;

            .image-container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    transition: all 0.3s ease-in-out;
                }
            }
        }

        .project-details-content {
            height: 25%;
            margin: 20px 0 0 20px;
            color: #fff;
            display: flex;
            justify-content: space-between;


            @media (max-width: 800px) {
                margin: 20px 0 20px 20px;
            }

            .left {
                h3 {
                    color: #fff;
                    font-size: 1.5rem;
                    font-weight: 800;
                }

                p {
                    color: #bc82ff;
                    font-size: 1rem;
                    font-weight: 400;
                    font-family: 'Fira Code', monospace;
                    margin-top: 10px;
                }
            }

            .right {
                margin: 20px 20px 0 0;

                a {
                    font-weight: 500;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: #bc82ff;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}