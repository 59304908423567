.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 0px 200px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
    z-index: 1000;
    font-family: 'Fira Code', monospace;

    @media (max-width: 1280px) {
        padding: 5px 100px;
    }

    @media (max-width: 979px) {
        padding: 5px 50px;
    }

    @media (max-width: 768px) {
        display: none;
    }
}

.navbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #80808018;
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
    z-index: -1;
}

.navbar--scroll {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    z-index: 1000;
}

.navbar--scroll::before {
    height: 100%;
    transition: all 0.3s ease;
}


.navbar .nav-links {
    display: flex;
    gap: 30px;
    align-items: center;
}

.navbar a {
    text-decoration: none;
    color: #fff;
    transition: all 0.3s ease;
}

.navbar .nav-links a {
    font-size: 1.2rem;
    font-weight: 600;
}

.navbar .nav-links a:hover {
    transform: scale(1.1);
    color: #bc82ff;
}

.navbar--home a {
    color: #fff;
}

.navbar .activenav {
    color: #9d00ff;
}

.navbar .activenav #lightnav {
    color: #eed2ff;
}


.navbar--scroll a {
    color: #ffffff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.navbar .cta {
    a {
        --color: #bc82ff;
        font-family: inherit;
        display: inline-block;
        width: 9em;
        height: 2.6em;
        line-height: 2.5em;
        margin: 20px;
        position: relative;
        overflow: hidden;
        border: 2px solid var(--color);
        transition: color .5s;
        z-index: 1;
        font-size: 17px;
        border-radius: 60px;
        font-weight: 500;
        color: var(--color);
        display: flex;
        justify-content: center;

    }

    a:before {
        content: "";
        position: absolute;
        z-index: -1;
        background: #560bad;
        height: 150px;
        width: 200px;
        border-radius: 50%;
    }

    a:hover {
        color: #fff;
    }

    a:before {
        top: 100%;
        left: 100%;
        transition: all .7s;
    }

    a:hover:before {
        top: -30px;
        left: -30px;
    }

    a:active:before {
        background: #3a0ca3;
        transition: background 0s;
    }
}

.content {
    padding: 80px 20px 20px;
    margin-top: 60px;
}



// 
//-----------------------------------------------------------------------
// Mobile Navbar
//
//_____________________________________________________________________

.mobile-nav-toggle {
    position: fixed;
    top: 15px;
    right: 5vw;
    z-index: 11000;
    width: 90vw;
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 20px;
    box-sizing: border-box;
    background-color: #601cff18;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
        display: flex;
    }

    .logo {
        width: 140px;

        img {
            width: 100%;
        }
    }

    .hamburger {
        background-color: #bc82ff;
        padding: 5px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        button {
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            font-family: inherit;
        }

        .menu__icon {
            width: 32px;
            height: 32px;
            padding: 4px;
        }

        .menu__icon span {
            display: block;
            width: 100%;
            height: 0.125rem;
            border-radius: 2px;
            background-color: #fff;
            box-shadow: 0 .5px 2px 0 hsla(0, 0%, 0%, .2);
            transition: background-color .4s;
            position: relative;
        }

        .menu__icon span+span {
            margin-top: .375rem;
        }

        .menu__icon span:nth-child(1) {
            animation: ease .8s menu-icon-top-2 forwards;
        }

        .menu__icon span:nth-child(2) {
            animation: ease .8s menu-icon-scaled-2 forwards;
        }

        .menu__icon span:nth-child(3) {
            animation: ease .8s menu-icon-bottom-2 forwards;
        }

        .active-menu span:nth-child(1) {
            animation: ease .8s menu-icon-top forwards;
        }

        .active-menu span:nth-child(2) {
            animation: ease .8s menu-icon-scaled forwards;
        }

        .active-menu span:nth-child(3) {
            animation: ease .8s menu-icon-bottom forwards;
            background-color: #560bad;
        }

        @keyframes menu-icon-top {
            0% {
                top: 0;
                transform: rotate(0);
            }

            50% {
                top: .5rem;
                transform: rotate(0);
            }

            100% {
                top: .5rem;
                transform: rotate(45deg);
            }
        }

        @keyframes menu-icon-top-2 {
            0% {
                top: .5rem;
                transform: rotate(45deg);
            }

            50% {
                top: .5rem;
                transform: rotate(0);
            }

            100% {
                top: 0;
                transform: rotate(0);
            }
        }

        @keyframes menu-icon-bottom {
            0% {
                bottom: 0;
                transform: rotate(0);
            }

            50% {
                bottom: .5rem;
                transform: rotate(0);
            }

            100% {
                bottom: .5rem;
                transform: rotate(135deg);
            }
        }

        @keyframes menu-icon-bottom-2 {
            0% {
                bottom: .5rem;
                transform: rotate(135deg);
            }

            50% {
                bottom: .5rem;
                transform: rotate(0);
            }

            100% {
                bottom: 0;
                transform: rotate(0);
            }
        }

        @keyframes menu-icon-scaled {
            50% {
                transform: scale(0);
            }

            100% {
                transform: scale(0);
            }
        }

        @keyframes menu-icon-scaled-2 {
            0% {
                transform: scale(0);
            }

            50% {
                transform: scale(0);
            }

            100% {
                transform: scale(1);
            }
        }
    }
}

.mobile-nav-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 0.3s ease;
    display: none;
    z-index: -1;

    @media screen and (max-width: 768px) {
        display: flex;
    }

    .mobile-nav-box {
        width: 90vw;
        height: 90vh;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        box-sizing: border-box;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;

        .mobile-nav-links {
            display: none;
        }

        .mobile-nav-contact {
            display: none;
        }
    }
}

.mobile-nav-container-active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 0.3s ease;
    overflow: hidden;

    .mobile-nav-box {
        width: 90vw;
        height: 80vh;
        background-color: #2d2239;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        box-sizing: border-box;
        opacity: 1;
        visibility: visible;
        transition: all 0.3s ease;
        // backdrop-filter: blur(2px);

        @media screen and (max-height: 850px) {
            height: 75vh;
        }

        @media screen and (max-height: 700px) {
            height: 70vh;
        }

        .mobile-nav-links {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            width: 100%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;

            a {
                text-decoration: none;
                font-size: 2rem;
                color: #fff;
                transition: all 0.3s ease;
                font-family: 'Fira Code', monospace;
                font-weight: 700;
                margin-bottom: 20px;
            }

            a:hover {
                transform: scale(1.1);
            }
        }

        .mobile-nav-contact {
            background-color: #9302F0;
            padding: 10px 20px;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            transition: all 0.3s ease;

            a {
                text-decoration: none;
                font-size: 18px;
                color: #fff;
                transition: all 0.3s ease;
            }
        }

        .mobile-nav-contact:hover {
            transition: all 0.3s ease;
            transform: scale(1.1);
        }
    }
}