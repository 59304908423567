.chat-button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 100;

    @media (max-width: 768px) {
        bottom: 20px;
        right: 20px;
    }

    .loader {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loader::before,
    .loader::after {
        position: absolute;
        content: "";
        height: 8em;
        width: 8em;
        border: 1em solid #9302F0;
        border-radius: 50%;
        animation: loader_79178 2s linear infinite;
    }

    .loader::after {
        opacity: 0;
        animation-delay: 1s;
    }

    @keyframes loader_79178 {
        0% {
            border: 1em solid #bc82ff;
            transform: scale(0);
            opacity: 1;
        }

        100% {
            border: 0 solid #9302F0;
            transform: scale(1);
            opacity: 0;
        }
    }

    .chatBtn {
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: none;
        background-color: #560bad;
        background-image: linear-gradient(147deg, #bc82ff, #9302F0, #bc82ff);
        cursor: pointer;
        padding-top: 3px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.164);
        position: relative;
        background-size: 300%;
        background-position: left;
        transition-duration: 1s;
        z-index: 1000;
    }

    .tooltip {
        position: absolute;
        top: -40px;
        opacity: 0;
        background-color: #9302F0;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition-duration: .5s;
        pointer-events: none;
        letter-spacing: 0.5px;
    }

    .chatBtn:hover .tooltip {
        opacity: 1;
        transition-duration: .5s;
    }

    .chatBtn:hover {
        background-position: right;
        transition-duration: 1s;
    }
}