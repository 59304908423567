.logo {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Fira Code', monospace;
    transition: all 0.3s;

    &:hover {
        transform: scale(1.1);
        transition: all 0.3s;
    }

    a {
        color: #0bc2ff !important;
        text-decoration: none;
    }

    .name {
        color: #bc82ff;
    }

    .dot {
        color: #ffffff;
    }
}

.ant-message-notice-content {
    background-color: #1a0c29 !important;
    color: #fff;
    border: 1px solid #bc82ff;
    border-radius: 50px !important;
    font-size: 0.9rem;
    font-family: 'Fira Code', monospace;


    .ant-message-custom-content {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0 20px;
    }
}

.App {
    background-color: #06040b;
    overflow: hidden;

    .main-title {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 20px;
        color: #fff;

        .number {
            color: #bc82ff;
            font-size: 2rem;
            font-family: 'Fira Code', monospace;
        }

        .title::after {
            content: '';
            display: inline-block;
            width: 100px;
            height: 2px;
            background-color: #bc82ff;
            margin-bottom: 10px;
            margin-left: 10px;
        }
    }

    nav {
        z-index: 2;
        width: 100vw;
        position: fixed;

        .nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;
            padding: 20px 200px;
            font-family: 'Fira Code', monospace;

            a {
                color: white;
                text-decoration: none;
            }

            .logo {
                font-size: 1.5rem;
                font-weight: 700;

                a {
                    color: #0bc2ff;
                }

                .name {
                    color: #bc82ff;
                }

                .dot {
                    color: #ffffff;
                }
            }

            ul {
                display: flex;
                list-style: none;

                li {
                    margin-left: 2rem;
                    font-size: 1.2rem;
                    font-weight: 500;
                    transition: all 0.3s;

                    a {
                        color: white;
                        text-decoration: none;
                    }

                    &:hover {
                        transform: scale(1.1);
                        transition: all 0.3s;

                        a {
                            color: rgb(192, 141, 255);
                        }
                    }
                }
            }

            .cta {
                a {
                    --color: #560bad;
                    font-family: inherit;
                    display: inline-block;
                    width: 9em;
                    height: 2.6em;
                    line-height: 2.5em;
                    margin: 20px;
                    position: relative;
                    overflow: hidden;
                    border: 2px solid var(--color);
                    transition: color .5s;
                    z-index: 1;
                    font-size: 17px;
                    border-radius: 60px;
                    font-weight: 500;
                    color: var(--color);
                }

                a:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    background: var(--color);
                    height: 150px;
                    width: 200px;
                    border-radius: 50%;
                }

                a:hover {
                    color: #fff;
                }

                a:before {
                    top: 100%;
                    left: 100%;
                    transition: all .7s;
                }

                a:hover:before {
                    top: -30px;
                    left: -30px;
                }

                a:active:before {
                    background: #3a0ca3;
                    transition: background 0s;
                }
            }
        }
    }

    .hero {
        height: 100vh;

        .contents {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: rgb(255, 254, 242);
            width: 100%;
            height: 100%;

            @media (max-width: 800px) {
                align-items: start;
                padding-bottom: 90px;
            }

            h1 {
                font-size: 8rem;
                font-weight: 700;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                margin: 0 0 30px 0;

                @media (max-width: 1600px) {
                    font-size: 7rem;
                }

                @media (max-width: 1200px) {
                    font-size: 5rem;
                }

                @media (max-width: 800px) {
                    font-size: 4rem;
                    text-align: start;
                    margin: 0 20px 30px 20px;
                }
            }

            .type-animation {
                font-size: 2.5rem;
                display: inline-block;
                font-weight: 500;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                margin: 0;

                @media (max-width: 1200px) {
                    font-size: 2rem;
                }

                @media (max-width: 800px) {
                    font-size: 1.2rem;
                    text-align: center;
                    margin: 0 20px 0 20px;
                    position: relative;
                }
            }
        }

        .social {
            position: absolute;
            z-index: 20;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 30%;
            left: 20px;
            gap: 20px;
            background-color: #ffffff27;
            border-radius: 50px;
            padding: 20px 0;
            backdrop-filter: blur(1px);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

            a {
                margin: 0 10px;
                font-size: 1.3rem;
                text-decoration: none;
                color: #a2a2a2;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.3s;

                &:hover {
                    transform: scale(1.1);
                    transition: all 0.3s;
                    color: #ffffff;
                }
            }

            @media (max-width: 800px) {
                // position: relative;
                flex-direction: row;
                top: unset;
                // left: 5%;
                bottom: 20%;
                padding: 5px 10px;
                gap: 5px;
                width: 75%;
                background-color: #ffffff10;

                a {
                    font-size: 1.2rem;
                }
            }
        }
    }

    .about {
        padding: 100px 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;

        @media (max-width: 1400px) {
            padding: 100px 100px;
        }

        @media (max-width: 800px) {
            flex-direction: column;
            padding: 60px 20px;
        }

        .left {
            width: 55%;

            @media (max-width: 800px) {
                width: 100%;
            }

            p {
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 1.6;
                margin: 40px 0;
                color: #e5dfecb7;

                @media (max-width: 1256px) {
                    font-size: 1rem;
                }
            }
        }

        .right {
            width: 40%;
            display: flex;
            // justify-content: center;
            // align-items: center;
            flex-direction: column;

            @media (max-width: 800px) {
                width: 100%;
                justify-content: center;
                align-items: center;
            }

            .image-container {
                width: 400px;
                position: relative;

                @media (max-width: 1256px) {
                    width: 300px;
                }

                img {
                    width: 100%;
                    border-radius: 10px;
                    transition: all 0.6s;

                    &:hover {
                        filter: grayscale(0%);
                        transition: all 0.6s;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #1a0c2994;
                    border-radius: 10px;
                    transition: all 0.6s;
                }

                &:hover::before {
                    opacity: 0;
                }
            }
        }
    }

    .expertise {
        padding: 50px 200px 100px 200px;

        @media (max-width: 1400px) {
            padding: 100px 100px;
        }

        @media (max-width: 800px) {
            padding: 60px 20px;
        }

        .main-title {
            margin: 0 auto;
            width: fit-content;
        }

        .expertise-container {
            color: #fff;
            margin-top: 50px;

            .expertise-item {
                background-color: #13111e;
                height: 450px;
                padding: 30px;

                @media (max-width: 1256px) {
                    height: 400px;
                }

                @media (max-width: 992px) {
                    height: 100%;
                }

                .item-header {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    h2 {
                        color: #fff;
                        width: 200px;
                        line-height: 1.3;
                        z-index: 5;
                        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                    }

                    .bar {
                        content: "";
                        position: absolute;
                        width: 100px;
                        height: 10px;
                        top: 48px;
                        left: 104px;
                    }

                    .svg {
                        color: #fff;
                    }
                }

                .items {
                    margin-top: 25px;

                    p {
                        font-size: 1.2rem;
                        color: #3d3d3d;

                        @media (max-width: 1256px) {
                            font-size: 1rem;
                        }
                    }

                    ul {
                        margin-left: 20px;
                        border-left: 1px solid #3d3d3d;
                        padding-left: 20px;

                        li {
                            list-style: none;
                            color: #d9d9d9;
                            font-size: 1.1rem;
                            margin: 10px 0;
                            font-family: 'Fira Code', monospace;

                            @media (max-width: 1256px) {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .experience {
        padding: 50px 400px 100px 400px;

        @media (max-width: 1700px) {
            padding: 100px 300px;
        }

        @media (max-width: 1500px) {
            padding: 100px 200px;
        }

        @media (max-width: 1300px) {
            padding: 100px 100px;
        }

        @media (max-width: 800px) {
            padding: 60px 20px;
        }

        .main-title {
            margin: 0 auto;
            width: fit-content;
        }

        .experience-container {
            margin-top: 50px;

            .collapse {
                // background: #fff;

                .ant-collapse-header {
                    background-color: #27124b;
                    border-radius: 10px;
                    padding: 20px 30px;
                    display: flex;
                    align-items: center;

                    h2 {
                        width: 70%;
                    }

                    .ant-collapse-expand-icon {
                        color: #fff;
                    }

                    .collapse-item-heder {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #fff;
                    }
                }

                .ant-collapse-content {
                    margin-top: 20px;
                    background-color: #13111e;
                    border-radius: 10px;
                }

                .collapse-item-body {
                    display: flex;
                    justify-content: space-between;
                    color: #fff;
                    margin-top: 20px;

                    @media (max-width: 800px) {
                        flex-direction: column;
                    }

                    .left {
                        order: 1;
                        width: 70%;

                        @media (max-width: 800px) {
                            width: 100%;
                            order: 2;
                        }

                        .header {
                            display: flex;
                            align-items: center;
                            gap: 20px;

                            h3 {
                                color: #fff;
                                font-size: 1.1rem;
                                font-weight: 700;
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                font-family: 'Fira Code', monospace;

                                @media (max-width: 800px) {
                                    font-size: 1rem;
                                }
                            }

                            a {
                                color: #fff;
                                font-size: 1.1rem;
                                font-weight: 700;
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                font-family: 'Fira Code', monospace;

                                @media (max-width: 800px) {
                                    font-size: 1rem;
                                }
                            }
                        }

                        p {
                            font-size: 1.1rem;
                            color: #d9d9d9;
                            margin: 20px 0 15px 0;

                            @media (max-width: 800px) {
                                font-size: 1rem;
                            }
                        }

                        .tags {
                            display: grid;
                            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                            gap: 10px;


                            span {
                                background-color: #322862;
                                padding: 10px 15px;
                                border-radius: 50px;
                                font-size: 0.8rem;
                                color: #fff;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;

                                @media (max-width: 800px) {
                                    font-size: 0.7rem;
                                    padding: 10px;
                                }
                            }
                        }
                    }

                    .right {
                        order: 2;
                        width: 25%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media (max-width: 800px) {
                            width: 100%;
                            order: 1;
                            padding: 20px 0;
                        }

                        .image-container {
                            width: 200px;

                            @media (max-width: 800px) {
                                width: 100px;
                            }

                            img {
                                width: 100%;
                                border-radius: 10px;
                                transition: all 0.6s;

                                &:hover {
                                    filter: grayscale(0%);
                                    transition: all 0.6s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .education {
        padding: 50px 200px 100px 200px;

        @media (max-width: 1400px) {
            padding: 100px 100px;
        }

        @media (max-width: 800px) {
            padding: 60px 20px;
        }

        .main-title {
            margin: 0 auto;
            width: fit-content;
        }

        .education-container {
            margin-top: 50px;
            color: #fff;

            .header {
                align-items: center;
                color: #fff;
                margin-bottom: 20px;

                h2 {
                    font-size: 1.5rem;
                    font-weight: 800;
                }

                p {
                    font-size: 1rem;
                    font-weight: 400;
                    font-family: 'Fira Code', monospace;
                }
            }

            .body {
                p {
                    font-size: 1.2rem;
                    color: #6c6c6c;
                }
            }

            .ant-timeline-item-tail {
                background-color: #bc82ff;
                height: 282px;

                @media (max-width: 800px) {
                    height: 306px;
                }

                @media (max-width: 600px) {
                    height: 356px;
                }
            }
        }
    }


    .myWork {
        padding: 50px 200px 100px 200px;

        @media (max-width: 1400px) {
            padding: 100px 100px;
        }

        @media (max-width: 800px) {
            padding: 60px 20px;
        }

        .myWork-container {
            margin-top: 50px;
        }
    }

    .references {
        padding: 50px 200px 100px 200px;

        @media (max-width: 1400px) {
            padding: 100px 100px;
        }

        @media (max-width: 800px) {
            padding: 100px 20px;
        }

        .main-title {
            margin: 0 auto;
            width: fit-content;
        }

        .references-container {
            margin-top: 50px;

            .reference-item {
                background-color: #13111e;
                min-height: 500px;
                padding: 30px;
                margin: 0 10px;
                border-radius: 10px;
                transition: all 0.3s ease-in-out;

                @media (max-width: 992px) {
                    min-height: 100%;
                }

                &:hover {
                    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
                    transition: all 0.3s ease-in-out;
                    background-color: #252139;
                    scale: 1.01;

                    .imageContainer {
                        &::before {
                            opacity: 0;
                        }
                    }

                    .item-header {
                        h2 {
                            color: #ffffffc2;
                        }
                    }
                }

                .item-header {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    h2 {
                        color: #f0e3ff86;
                        width: 200px;
                        line-height: 1.3;
                        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                        font-size: 1.5rem;
                        font-weight: 800;
                        transition: all 0.3s ease-in-out;

                        @media (max-width: 1256px) {
                            font-size: 1.3rem;
                        }

                        @media (max-width: 800px) {
                            font-size: 1.2rem;
                        }
                    }

                    .imageContainer {
                        width: 100px;
                        border-radius: 50%;
                        overflow: hidden;
                        position: relative;

                        img {
                            display: block;
                            width: 100%;
                            object-fit: cover;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: #1a0c29a7;
                            border-radius: 10px;
                            transition: all 0.5s ease-in-out;
                        }
                    }
                }

                .items {
                    margin-top: 25px;
                    height: 260px;

                    @media (max-width: 1500px) {
                        height: 300px;
                    }

                    @media (max-width: 992px) {
                        height: 100%;
                    }

                    p {
                        font-size: 1.2rem;
                        color: #3d3d3d;
                    }

                    ul {
                        margin-left: 20px;
                        border-left: 1px solid #3d3d3d;
                        padding-left: 20px;

                        li {
                            list-style: none;
                            color: #d9d9d9;
                            font-size: 1.1rem;
                            margin: 15px 0;
                            font-family: 'Fira Code', monospace;
                        }
                    }
                }

                .contacts {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 10px;

                    .contact {
                        color: #c4c4c4;
                        margin-top: 10px;
                        font-size: 1.1rem;

                        &:hover {
                            color: #bc82ff;
                        }
                    }
                }
            }
        }
    }

    .getintouch {
        padding: 50px 200px 100px 200px;

        @media (max-width: 1400px) {
            padding: 100px 100px;
        }

        @media (max-width: 800px) {
            padding: 60px 20px;
        }

        .main-title {
            margin: 0 auto;
            width: fit-content;
        }

        .getintouch-container {
            margin-top: 50px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: start;

            @media (max-width: 800px) {
                flex-direction: column;
            }

            .left {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    color: #6c6c6c;
                }

                @media (max-width: 800px) {
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 30px;

                    p {
                        text-align: center;
                        font-size: 1rem;
                    }
                }
            }

            .right {
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 800px) {
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                }

                .ant-spin-nested-loading {
                    width: 100%;
                }

                form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    label {
                        color: #fff;
                        font-size: 1rem;
                        margin-bottom: 5px;
                    }

                    input[type="text"],
                    input[type="email"],
                    textarea {
                        background-color: #1a0c29;
                        color: #fff;
                        border: 1px solid #bc82ff;
                        border-radius: 10px;
                        padding: 15px;
                        font-size: 1.1rem;
                        margin-bottom: 20px;

                        &::placeholder {
                            color: #ffffff51;
                        }
                    }

                    button {
                        background-color: #560bad;
                        border: 1px solid #560bad;
                        border-radius: 10px;
                        color: #fff;
                        font-size: 1.1rem;
                        font-weight: 600;
                        transition: all 0.3s;
                        height: 50px;
                        width: 200px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        gap: 10px;

                        @media (max-width: 800px) {
                            width: 100%;
                        }

                        &:hover {
                            background-color: #bc82ff;
                            border: 1px solid #bc82ff;
                            transition: all 0.3s;
                        }
                    }
                }
            }
        }

        .contact-ends {
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            flex-direction: column;
            margin-top: 100px;
            background-color: #1a0c29;
            padding: 70px 0;
            border-radius: 10px;

            @media (max-width: 800px) {
                margin-top: 50px;
            }

            h2 {
                font-size: 1.5rem;
                font-weight: 800;
                color: #fff;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                text-align: center;
                padding: 0 20px;

                @media (max-width: 800px) {
                    font-size: 1.3rem;
                }
            }

            .connect {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 60px;
                margin-top: 20px;

                @media (max-width: 800px) {
                    flex-direction: column;
                    gap: 20px;
                }

                a {
                    color: #fff;
                    font-size: 1.1rem;
                    transition: all 0.3s;
                    text-decoration: none;

                    &:hover {
                        transform: scale(1.1);
                        transition: all 0.3s;
                        color: #bc82ff;
                    }
                }

            }
        }
    }

    footer {
        padding: 50px;
        background-color: #120c21;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        position: relative;
        z-index: 10000;

        p {
            font-size: 1rem;
            font-weight: 500;
            color: #ffffffa1;
            font-family: 'Fira Code', monospace;
            text-align: center;
        }

        .top {
            position: absolute;
            bottom: 30px;
            right: 50px;

            .button {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: rgb(18, 9, 42);
                border: none;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
                cursor: pointer;
                transition-duration: 0.3s;
                overflow: hidden;
                position: relative;
            }

            .svgIcon {
                width: 12px;
                transition-duration: 0.3s;
            }

            .svgIcon path {
                fill: white;
            }

            .button:hover {
                width: 140px;
                border-radius: 50px;
                transition-duration: 0.3s;
                background-color: rgb(181, 160, 255);
                align-items: center;
            }

            .button:hover .svgIcon {
                transition-duration: 0.3s;
                transform: translateY(-200%);
            }

            .button::before {
                position: absolute;
                bottom: -20px;
                content: "Back to Top";
                color: white;
                font-size: 0px;
            }

            .button:hover::before {
                font-size: 13px;
                opacity: 1;
                bottom: unset;
                transition-duration: 0.3s;
            }
        }
    }
}