.project-page {
    background-color: #06040b;
    overflow: hidden;
    min-height: 100vh;
    color: #fff;

    .project-container {
        padding: 200px 200px 100px 200px;

        @media (max-width: 1400px) {
            padding: 180px 100px 80px 100px;
        }

        @media (max-width: 800px) {
            padding: 150px 20px 50px 20px;
        }

        .type {
            font-size: 1.2rem;
            font-weight: 600;
            margin: 0px;
            color: #757575;

            .dot {
                color: #0bc2ff !important;
            }

            @media (max-width: 800px) {
                font-size: 1rem;
            }
        }

        .title {
            font-size: 5rem;
            font-weight: 700;
            margin-bottom: 20px;
            width: 70%;

            @media (max-width: 800px) {
                font-size: 3rem;
            }
        }

        .breadcrumb {
            margin: 100px 0;

            @media (max-width: 800px) {
                margin: 50px 0;
            }

            .ant-breadcrumb-separator {
                color: #bebebe;
            }

            .ant-breadcrumb-link {
                color: #bebebe;
                font-weight: 500;
            }

            .ant-breadcrumb-link:hover {
                color: #bc82ff;
            }

            .ant-breadcrumb-link::after {
                color: #bc82ff;
            }
        }

        .project-content {
            display: flex;
            gap: 100px;
            width: 90%;

            @media (max-width: 800px) {
                flex-direction: column;
                gap: 50px;
            }

            .left {
                width: 50%;

                @media (max-width: 800px) {
                    width: 100%;
                }

                p {
                    font-size: 1.2rem;
                    font-weight: 500;
                    line-height: 1.8;
                    margin: 0;
                    color: #bebebe;

                    @media (max-width: 800px) {
                        font-size: 1rem;
                    }
                }
            }

            .right {
                border-top: 1px solid #bebebe;
                padding-top: 20px;

                @media (max-width: 800px) {
                    width: fit-content;
                }

                h3 {
                    font-size: 2rem;
                    font-weight: 700;
                    margin: 0;
                }

                p {
                    font-size: 1.2rem;
                    font-weight: 400;
                    line-height: 1.8;
                    margin: 0;
                    color: #bebebe;
                    margin-top: 10px;
                }

                h4 {
                    font-size: 1.2rem;
                    font-weight: 500;
                    margin: 0;
                    margin-top: 20px;
                }

                .project-origin {
                    display: flex;
                    gap: 10px;
                    margin-top: 10px;

                    img {
                        height: 35px;
                        object-fit: cover;
                    }

                    p {
                        font-size: 1.2rem;
                        font-weight: 500;
                        line-height: 1.8;
                        margin: 0;
                        color: #bebebe;
                    }
                }
            }
        }

        .open-project-container {
            margin: 80px 0;

            @media (max-width: 800px) {
                margin: 50px 0;
            }

            .open-project {
                color: #fff;
                font-size: 1.2rem;
                font-weight: 600;
                text-decoration: none;
                transition: all 0.3s;
                display: flex;
                align-items: center;
                gap: 10px;

                &:hover {
                    color: #bc82ff;
                    transition: all 0.3s;
                }
            }
        }

        .tech-stack {
            h3 {
                font-size: 2rem;
                font-weight: 700;
                margin: 0;
                font-family: 'Fira Code', monospace;

                @media (max-width: 800px) {
                    font-size: 1.5rem;
                }
            }

            .tech-stack-content {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                gap: 20px;
                margin-top: 20px;

                @media (max-width: 800px) {
                    gap: 10px;
                }

                span {
                    background-color: #322862;
                    padding: 10px 15px;
                    border-radius: 50px;
                    font-size: 0.8rem;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    @media (max-width: 800px) {
                        font-size: 0.7rem;
                        padding: 10px;
                    }
                }
            }
        }

        .project-images {
            margin-top: 100px;
            display: flex;
            gap: 20px;
            flex-direction: column;
            width: 80%;

            @media (max-width: 800px) {
                margin-top: 50px;
                width: 100%;
            }

            img {
                width: 100%;
                border-radius: 5px;
                transition: all 0.3s;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    footer {
        padding: 50px;
        background-color: #120c21;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        position: relative;

        p {
            font-size: 1rem;
            font-weight: 500;
            color: #ffffffa1;
            font-family: 'Fira Code', monospace;
            text-align: center;
        }

        .top {
            position: absolute;
            bottom: 30px;
            right: 50px;

            .button {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: rgb(18, 9, 42);
                border: none;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
                cursor: pointer;
                transition-duration: 0.3s;
                overflow: hidden;
                position: relative;
            }

            .svgIcon {
                width: 12px;
                transition-duration: 0.3s;
            }

            .svgIcon path {
                fill: white;
            }

            .button:hover {
                width: 140px;
                border-radius: 50px;
                transition-duration: 0.3s;
                background-color: rgb(181, 160, 255);
                align-items: center;
            }

            .button:hover .svgIcon {
                transition-duration: 0.3s;
                transform: translateY(-200%);
            }

            .button::before {
                position: absolute;
                bottom: -20px;
                content: "Back to Top";
                color: white;
                font-size: 0px;
            }

            .button:hover::before {
                font-size: 13px;
                opacity: 1;
                bottom: unset;
                transition-duration: 0.3s;
            }
        }
    }
}